exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-speakers-jsx": () => import("./../../../src/pages/en/speakers.jsx" /* webpackChunkName: "component---src-pages-en-speakers-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-speakers-jsx": () => import("./../../../src/pages/speakers.jsx" /* webpackChunkName: "component---src-pages-speakers-jsx" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-10-serhii-demediuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/10-serhii-demediuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-10-serhii-demediuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-12-george-dubinsky-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/12-george-dubinsky/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-12-george-dubinsky-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-13-anton-demokhin-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/13-anton-demokhin/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-13-anton-demokhin-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-14-kateryna-chernohorenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/14-kateryna-chernohorenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-14-kateryna-chernohorenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-15-nataliya-tkachuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/15-nataliya-tkachuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-15-nataliya-tkachuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-16-illia-vitiuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/16-illia-vitiuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-16-illia-vitiuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-18-oleksandr-potii-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/18-oleksandr-potii/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-18-oleksandr-potii-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-19-michael-dignam-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/19-michael-dignam/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-19-michael-dignam-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-20-tanel-sepp-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/20-tanel-sepp/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-20-tanel-sepp-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-21-sandra-joyce-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/21-sandra-joyce/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-21-sandra-joyce-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-22-tadeusz-chomicki-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/22-tadeusz-chomicki/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-22-tadeusz-chomicki-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-24-mykhailo-verych-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/24-mykhailo-verych/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-24-mykhailo-verych-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-25-serhii-prokopenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/25-serhii-prokopenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-25-serhii-prokopenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-26-aaron-brantly-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/26-aaron-brantly/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-26-aaron-brantly-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-27-oleksandr-komarov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/27-oleksandr-komarov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-27-oleksandr-komarov-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-29-arnaud-dassier-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/29-arnaud-dassier/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-29-arnaud-dassier-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-34-andrii-paziuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/34-andrii-paziuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-34-andrii-paziuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-39-jane-klepa-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/39-jane-klepa/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-39-jane-klepa-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-43-andrew-borene-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/43-andrew-borene/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-43-andrew-borene-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-44-oleksandr-ryzhenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/44-oleksandr-ryzhenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-44-oleksandr-ryzhenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-49-iryna-khomenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/49-iryna-khomenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-49-iryna-khomenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-53-yuliia-yanchuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/53-yuliia-yanchuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-53-yuliia-yanchuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-54-anton-tarasyuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/54-anton-tarasyuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-54-anton-tarasyuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-55-ihor-solovei-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/55-ihor-solovei/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-55-ihor-solovei-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-56-andriy-krasnyy-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/56-andriy-krasnyy/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-56-andriy-krasnyy-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-57-serhii-khariuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/57-serhii-khariuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-57-serhii-khariuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-59-ilja-david-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/59-ilja-david/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-59-ilja-david-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-60-andrii-mankish-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/60-andrii-mankish/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-60-andrii-mankish-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-61-matthew-h-murray-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/61-matthew-h-murray/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-61-matthew-h-murray-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-62-anastasiia-ostrovska-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/62-anastasiia-ostrovska/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-62-anastasiia-ostrovska-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-66-oleg-naumenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/66-oleg-naumenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-66-oleg-naumenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-68-igor-tkachenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/68-igor-tkachenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-68-igor-tkachenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-69-ievgen-vladimirov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/69-ievgen-vladimirov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-69-ievgen-vladimirov-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-70-ilona-khmeleva-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/70-ilona-khmeleva/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-70-ilona-khmeleva-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-74-christopher-fuchs-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/74-christopher-fuchs/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-74-christopher-fuchs-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-76-alex-yankovski-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/76-alex-yankovski/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-76-alex-yankovski-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-77-stanislav-petrov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/77-stanislav-petrov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-77-stanislav-petrov-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-10-serhii-demediuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/10-serhii-demediuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-10-serhii-demediuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-12-george-dubinsky-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/12-george-dubinsky/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-12-george-dubinsky-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-14-kateryna-chernohorenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/14-kateryna-chernohorenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-14-kateryna-chernohorenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-15-nataliya-tkachuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/15-nataliya-tkachuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-15-nataliya-tkachuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-16-illia-vitiuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/16-illia-vitiuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-16-illia-vitiuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-18-oleksandr-potii-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/18-oleksandr-potii/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-18-oleksandr-potii-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-19-michael-dignam-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/19-michael-dignam/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-19-michael-dignam-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-20-tanel-sepp-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/20-tanel-sepp/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-20-tanel-sepp-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-22-tadeusz-chomicki-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/22-tadeusz-chomicki/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-22-tadeusz-chomicki-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-24-mykhailo-verych-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/24-mykhailo-verych/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-24-mykhailo-verych-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-25-serhii-prokopenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/25-serhii-prokopenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-25-serhii-prokopenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-26-aaron-brantly-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/26-aaron-brantly/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-26-aaron-brantly-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-27-oleksandr-komarov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/27-oleksandr-komarov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-27-oleksandr-komarov-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-29-arnaud-dassier-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/29-arnaud-dassier/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-29-arnaud-dassier-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-31-dmytro-verbytskyi-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/31-dmytro-verbytskyi/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-31-dmytro-verbytskyi-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-34-andrii-paziuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/34-andrii-paziuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-34-andrii-paziuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-39-jane-klepa-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/39-jane-klepa/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-39-jane-klepa-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-44-oleksandr-ryzhenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/44-oleksandr-ryzhenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-44-oleksandr-ryzhenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-49-iryna-khomenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/49-iryna-khomenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-49-iryna-khomenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-53-yuliia-yanchuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/53-yuliia-yanchuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-53-yuliia-yanchuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-54-anton-tarasyuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/54-anton-tarasyuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-54-anton-tarasyuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-55-ihor-solovei-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/55-ihor-solovei/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-55-ihor-solovei-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-56-andriy-krasnyy-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/56-andriy-krasnyy/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-56-andriy-krasnyy-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-57-serhii-khariuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/57-serhii-khariuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-57-serhii-khariuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-60-andrii-mankish-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/60-andrii-mankish/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-60-andrii-mankish-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-62-anastasiia-ostrovska-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/62-anastasiia-ostrovska/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-62-anastasiia-ostrovska-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-68-igor-tkachenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/68-igor-tkachenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-68-igor-tkachenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-69-ievgen-vladimirov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/69-ievgen-vladimirov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-69-ievgen-vladimirov-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-70-ilona-khmeleva-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/70-ilona-khmeleva/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-70-ilona-khmeleva-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-71-nikita-veselkov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/71-nikita-veselkov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-71-nikita-veselkov-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-72-oleksii-semenyaka-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/72-oleksii-semenyaka/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-72-oleksii-semenyaka-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-74-christopher-fuchs-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/74-christopher-fuchs/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-74-christopher-fuchs-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-76-alex-yankovski-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/76-alex-yankovski/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-76-alex-yankovski-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-77-stanislav-petrov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/77-stanislav-petrov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-77-stanislav-petrov-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-en-call-for-papers-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/en/call-for-papers/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-en-call-for-papers-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-en-privacy-policy-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/en/privacy-policy/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-en-privacy-policy-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-uk-call-for-papers-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/uk/call-for-papers/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-uk-call-for-papers-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-uk-privacy-policy-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/uk/privacy-policy/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-uk-privacy-policy-index-md" */)
}

